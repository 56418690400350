import { styled } from "@mui/material/styles";
import { Stack } from "@mui/material";
import Banner from "./components/Banner";
import About from "./components/About";
// import Architecture from "./components/Architecture";
// import Security from "./components/Security";
// import Roadmap from "./components/Roadmap";
// import Service from "./components/Service";
import Needed from "./components/Needed";
import HowToWork from "./components/HowToWork";
import Restaking from "./components/Restaking";

const Containter = styled(Stack)`
  ${({ theme }) => ({
    [theme.breakpoints.down("lg")]: {},
  })};
`;

const Home = () => {
  return (
    <Containter>
      <Banner />
      <About />
      <Needed />
      <HowToWork />
      <Restaking />
    </Containter>
  );
};

export default Home;
