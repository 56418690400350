import { styled } from "@mui/material/styles";
import { Stack, Typography, Box, useTheme, useMediaQuery } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import aboutBanner from "assets/images/about-banner.png";
import { About1, About2, About3 } from "components/Svg";
import { Swiper, SwiperSlide } from "swiper/react";
import { ReactNode, useState } from "react";
import { Pagination } from "swiper/modules";

const BanBox = styled(Stack)`
  width: 100%;
  background: #0e0e0e;
  position: relative;
  overflow: hidden;
  ${({ theme }) => ({
    [theme.breakpoints.down("md")]: {},
  })};
`;

const Containter = styled(Stack)`
  width: 1200px;
  ${({ theme }) => ({
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  })};
`;

const Con = styled(Stack)`
  padding: 70px 10px 106px;
  ${({ theme }) => ({
    [theme.breakpoints.down("md")]: {
      padding: "40px 25px 90px 25px",
    },
  })};
`;

const ImgCon = styled("img")`
  width: 100%;
  height: auto;
`;

const AboutInfo = styled(Typography)`
  color: #939393;
  font-family: Arboria-Book;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
`;

const Dot = styled(Box)<{ color: string }>`
  width: 10px;
  height: 10px;
  background: ${({ color }) => (color ? color : "#F3B112")};
  border-radius: 50%;
`;

const Head = styled(Typography)`
  color: #fff;
  font-family: Arboria-Medium;
  font-size: 64px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  ${({ theme }) => ({
    [theme.breakpoints.down("md")]: {
      fontSize: "36px",
    },
  })};
`;

const IconBox = styled(Stack)`
  width: 78px;
  height: 78px;
  border-radius: 10px;
  border: 1px solid #3b3b3b;
`;

const IconText = styled(Typography)`
  color: #fff;
  font-family: Arboria-Book;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
`;

const SwiperBox = styled(Stack)`
  .swiper-slide {
    width: 190px;
  }
  .swiper-pagination-bullet {
    width: 34px;
    height: 4px;
    background: #565656;
    margin: 0 0 !important;
    border-radius: 0;
    &:first-child {
      border-radius: 28px 0 0 28px;
    }
    &:last-child {
      border-radius: 0 28px 28px 0;
    }
  }

  .swiper-pagination-bullet-active {
    color: #fff;
    border-radius: 28px;
    background: #f3b112;
  }
`;

interface CardItem {
  icon: ReactNode;
  title: string;
}

const About = () => {
  const theme = useTheme();
  const downToMd = useMediaQuery(theme.breakpoints.down("md"));
  const [swiperRef, setSwiperRef] = useState<any>(null);
  const pagination = {
    clickable: true,
    renderBullet: function (index: any, className: any) {
      return '<span class="' + className + '">' + 123 + "</span>";
    },
  };
  const aboutList: CardItem[] = [
    {
      icon: <About1 />,
      title: "Unleash BNB's potential, increase capital efficiency",
    },
    {
      icon: <About2 />,
      title: "Address trust fragmentation in BNB ecosystem",
    },
    {
      icon: <About3 />,
      title: "Amplify BNB ecosystem yield synergy",
    },
  ];
  return (
    <BanBox justifyContent="center" alignItems="center" direction="row">
      <Containter>
        <Con>
          {!downToMd ? (
            <Grid container spacing={130}>
              <Grid xs={6} md={6}>
                <ImgCon src={aboutBanner} alt="" />
                <AboutInfo mt={60}>
                  BinLayer is a staking platform for BNB and its LSD.
                </AboutInfo>
              </Grid>
              <Grid xs={6} md={6}>
                <Stack
                  justifyContent="flex-start"
                  alignItems="center"
                  direction="row"
                  mt={43}
                >
                  <Dot color={"#F3B112"} />
                  <Dot ml={5} color={"#fff"} />
                </Stack>
                <Head>About BinLayer</Head>
                <AboutInfo mt={10}>
                  BinLayer is a staking platform for BNB and its LSD. Through
                  authorising BinLayer’s smart contracts to impose additional
                  slashing conditions on BNB and it‘s LSD, stakers contribute to
                  enhanced cryptonomic security of the BNB ecosystem. By joining
                  BinLayer, stakers can validate a variety of modules including
                  data availability (DA), virtual machine (VM), guardian
                  network, oracle network, bridge, threshold cryptography
                  solution and trusted execution environment, etc.
                </AboutInfo>
                <Stack mt={104}>
                  <Grid container spacing={10}>
                    {aboutList.map((item: CardItem, index: number) => (
                      <Grid xs={4} md={4}>
                        <Box pr={35}>
                          <IconBox
                            justifyContent="center"
                            alignItems="center"
                            direction="row"
                          >
                            {item.icon}
                          </IconBox>
                          <IconText mt={15}>{item.title}</IconText>
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                </Stack>
              </Grid>
            </Grid>
          ) : (
            <Box>
              <Stack
                justifyContent="flex-start"
                alignItems="center"
                direction="row"
                mt={43}
              >
                <Dot color={"#F3B112"} />
                <Dot ml={5} color={"#fff"} />
              </Stack>
              <Head mt={10}>
                About<br></br> BinLayer
              </Head>
              <AboutInfo mt={26}>
                BinLayer is a staking platform for BNB and its LSD. Through
                authorising BinLayer’s smart contracts to impose additional
                slashing conditions on BNB and it‘s LSD, stakers contribute to
                enhanced cryptonomic security of the BNB ecosystem. By joining
                BinLayer, stakers can validate a variety of modules including
                data availability (DA), virtual machine (VM), guardian network,
                oracle network, bridge, threshold cryptography solution and
                trusted execution environment, etc.
              </AboutInfo>
              <SwiperBox p={20} mt={40}>
                <Swiper
                  onSwiper={setSwiperRef}
                  initialSlide={0}
                  spaceBetween={20}
                  pagination={true}
                  slidesPerView={"auto"}
                  modules={[Pagination]}
                  style={{ width: "100%" }}
                >
                  {aboutList.map((item: CardItem, index: number) => (
                    <SwiperSlide key={index}>
                      <Box height={230} pr={40}>
                        <IconBox
                          justifyContent="center"
                          alignItems="center"
                          direction="row"
                        >
                          {item.icon}
                        </IconBox>
                        <IconText mt={15}>{item.title}</IconText>
                      </Box>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </SwiperBox>
            </Box>
          )}
        </Con>
      </Containter>
    </BanBox>
  );
};

export default About;
