import { SvgIcon } from "@mui/material";
import { IconProps } from "../type";

const About1: React.FC<IconProps> = ({ width = "48px", height = "48px" }) => {
  return (
    <SvgIcon style={{ width, height }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="45"
        height="48"
        viewBox="0 0 45 48"
        fill="none"
      >
        <g clip-path="url(#clip0_30_521)">
          <path
            d="M22.5 21.0765C23.0241 21.0778 23.5264 21.2828 23.897 21.6466C24.2677 22.0105 24.4765 22.5036 24.4778 23.0181H28.4412C28.4399 21.8114 28.0583 20.6346 27.3487 19.6493C26.639 18.6639 25.6362 17.9183 24.4778 17.5148V15.2402H20.5153V17.5187C19.1935 17.9775 18.0795 18.8802 17.3699 20.0673C16.6604 21.2544 16.401 22.6494 16.6376 24.006C16.8743 25.3626 17.5917 26.5935 18.6631 27.4812C19.7345 28.3689 21.0911 28.8563 22.4931 28.8573C22.8842 28.8573 23.2666 28.9712 23.5919 29.1845C23.9171 29.3979 24.1706 29.7011 24.3203 30.0559C24.47 30.4106 24.5092 30.801 24.4328 31.1776C24.3565 31.5543 24.1682 31.9002 23.8916 32.1717C23.615 32.4433 23.2626 32.6282 22.8789 32.7031C22.4953 32.778 22.0976 32.7396 21.7362 32.5926C21.3748 32.4457 21.066 32.1968 20.8486 31.8775C20.6313 31.5582 20.5153 31.1829 20.5153 30.7989H16.5519C16.5532 32.0056 16.9348 33.1823 17.6444 34.1677C18.354 35.153 19.3569 35.8986 20.5153 36.3022V38.5806H24.4778V36.3061C25.7996 35.8472 26.9136 34.9445 27.6232 33.7574C28.3327 32.5703 28.5921 31.1753 28.3554 29.8187C28.1188 28.4621 27.4014 27.2312 26.33 26.3435C25.2586 25.4558 23.902 24.9684 22.5 24.9674C21.9744 24.9674 21.4703 24.7624 21.0987 24.3976C20.7271 24.0328 20.5183 23.5379 20.5183 23.022C20.5183 22.506 20.7271 22.0112 21.0987 21.6463C21.4703 21.2815 21.9744 21.0765 22.5 21.0765Z"
            fill="white"
          />
          <path
            d="M42.9738 12.2444L45 10.2562L39.2744 4.63057L37.2482 6.61872L39.1003 8.43795L36.6281 10.8649C34.5857 9.10135 32.2206 7.73486 29.6585 6.83812C29.6095 5.00668 28.8339 3.26642 27.497 1.98809C26.1602 0.709754 24.3677 -0.00561523 22.5015 -0.00561523C20.6353 -0.00561523 18.8428 0.709754 17.5059 1.98809C16.1691 3.26642 15.3935 5.00668 15.3445 6.83812C12.7824 7.73486 10.4172 9.10135 8.37484 10.8649L5.90264 8.43795L7.75481 6.61872L5.72859 4.63057L-0.000976562 10.2562L2.02425 12.2444L3.87642 10.4261L6.34862 12.853C2.90164 16.7158 1.00364 21.6775 1.00867 26.8128C1.00867 38.4951 10.6473 48 22.4961 48C34.3448 48 43.9825 38.4951 43.9825 26.8128C43.9875 21.6775 42.0895 16.7158 38.6425 12.853L41.1147 10.4261L42.9738 12.2444ZM22.504 2.81231C23.4654 2.81359 24.3988 3.1309 25.1551 3.71365C25.9115 4.29639 26.4473 5.11099 26.677 6.02752C23.9216 5.49035 21.0854 5.49035 18.3299 6.02752C18.5597 5.11156 19.0951 4.29741 19.8508 3.71475C20.6065 3.13208 21.5391 2.81441 22.5 2.81231H22.504ZM41.1256 26.8128C41.1256 36.9448 32.7715 45.1876 22.504 45.1876C12.2364 45.1876 3.88137 36.9448 3.88137 26.8128C3.88137 16.6808 12.2354 8.43795 22.504 8.43795C32.7725 8.43795 41.1216 16.6769 41.1216 26.8128H41.1256Z"
            fill="#F3B112"
          />
        </g>
        <defs>
          <clipPath id="clip0_30_521">
            <rect width="45" height="48" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
};

export default About1;
