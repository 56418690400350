import { SvgIcon } from "@mui/material";
import { IconProps } from "../type";

const Telegram: React.FC<IconProps> = ({ width = "30px", height = "30px" }) => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="20"
      className="telegram-svg"
      viewBox="0 0 24 20"
      fill="none"
      style={{ fill: "none", width, height }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="26"
        height="26"
        viewBox="0 0 26 26"
        fill="none"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M10.1305 19.5566L10.3881 15.6697L17.445 9.31067C17.7572 9.02627 17.3805 8.88829 16.9671 9.13646L8.25635 14.6408L4.48865 13.4457C3.67962 13.2163 3.67069 12.656 4.6723 12.2515L19.347 6.59124C20.018 6.28798 20.6613 6.75652 20.4037 7.78543L17.9046 19.5566C17.7299 20.3934 17.2242 20.5954 16.5263 20.2087L12.7219 17.397L10.8934 19.1709C10.6819 19.3818 10.5072 19.5566 10.1305 19.5566Z"
          fill="#272727"
          className="fill-color"
        />
      </svg>
    </SvgIcon>
  );
};

export default Telegram;
