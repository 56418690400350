import { IconProps } from "../type";

const FooterLogo: React.FC<IconProps> = ({ width = "162px", height = "29px" }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="162" height="29" viewBox="0 0 162 29" fill="none" style={{ width, height }}>
      <g clip-path="url(#clip0_61_1354)">
        <path d="M18.5948 0H0.994165C0.445103 0 0 0.445382 0 0.994788V7.63677C0 8.18618 0.445103 8.63156 0.994165 8.63156H18.5948C19.1439 8.63156 19.589 8.18618 19.589 7.63677V0.994788C19.589 0.445382 19.1439 0 18.5948 0Z" fill="white"/>
        <path d="M18.5948 16.541H0.994165C0.445103 16.541 0 16.9864 0 17.5358V24.1778C0 24.7272 0.445103 25.1726 0.994165 25.1726H18.5948C19.1439 25.1726 19.589 24.7272 19.589 24.1778V17.5358C19.589 16.9864 19.1439 16.541 18.5948 16.541Z" fill="white"/>
        <path d="M24.1107 5.09154L19.2865 0.264322C18.9339 -0.0884328 18.363 -0.0884328 18.0115 0.264322L6.48608 11.8043C6.05868 12.233 6.05868 12.9271 6.48608 13.3547L18.0105 24.8864C18.363 25.2391 18.9339 25.2391 19.2854 24.8864L24.1096 20.0592C24.4622 19.7064 24.4622 19.1351 24.1096 18.7834L18.5429 13.2132C18.1904 12.8605 18.1904 12.2892 18.5429 11.9375L24.1096 6.36729C24.4622 6.01453 24.4622 5.44326 24.1096 5.09154H24.1107Z" fill="white"/>
        <path d="M45.8284 22.5545H35.8379V2.0271H46.0312C50.3531 2.06144 52.539 3.73988 52.59 7.06348C52.6576 9.38708 51.5563 10.9469 49.2851 11.744C51.9452 12.5067 53.3179 14.1009 53.4032 16.5265C53.5893 20.6815 51.0644 22.6908 45.8274 22.5545H45.8284ZM40.3116 4.92718V10.2945H44.9632C46.9973 10.2945 48.0133 9.37875 48.0133 7.54734C48.0299 5.78357 46.937 4.91053 44.7344 4.92718H40.3116ZM45.4208 13.6774H40.3116V19.6555H45.3958C47.7512 19.7064 48.9035 18.7398 48.8535 16.7554C48.8192 14.7377 47.6753 13.7117 45.4218 13.6774H45.4208Z" fill="white"/>
        <path d="M56.0466 6.19872V1.97607H60.4954V6.19872H56.0466ZM56.0466 22.5295V7.85219H60.4954V22.5295H56.0466Z" fill="white"/>
        <path d="M78.1378 22.5295H73.6641V13.5753C73.6475 11.7096 72.6221 10.7689 70.588 10.7522H68.0714V22.5295H63.5977V7.85217H72.1136C76.1464 7.83552 78.1545 9.62426 78.1378 13.2194V22.5295Z" fill="white"/>
        <path d="M96.3643 22.5546H81.2646V2.0022H85.7384V19.6555H96.3643V22.5556V22.5546Z" fill="white"/>
        <path d="M98.6262 12.6087C99.5923 9.26849 102.117 7.57236 106.202 7.52137C111.286 7.40274 113.768 9.49742 113.65 13.8044V22.5296H104.778C100.593 22.5129 98.4993 21.0124 98.4993 18.027C98.3121 14.7544 100.736 13.1686 105.769 13.2705H109.379C109.311 11.5921 108.243 10.7263 106.176 10.6764C104.549 10.693 103.422 11.3382 102.795 12.6098H98.6262V12.6087ZM109.43 16.0426H106.074C103.888 16.026 102.812 16.6451 102.846 17.899C102.863 19.1373 103.923 19.7564 106.024 19.7564H109.431V16.0426H109.43Z" fill="white"/>
        <path d="M126.538 7.85229H131.342L125.343 23.1394C123.954 27.0228 121.86 28.8459 119.064 28.6086C117.826 28.6253 116.768 28.3547 115.886 27.7949V24.6409C116.581 25.0134 117.3 25.2091 118.047 25.2257C119.131 25.259 119.953 24.7762 120.512 23.7762L121.224 22.4536L115.198 7.85334H120.079L123.435 17.7482L126.536 7.85334L126.538 7.85229Z" fill="white"/>
        <path d="M148.527 16.5004H137.24C137.612 18.4504 139.163 19.4171 141.891 19.4005C143.908 19.4171 145.84 18.7387 147.688 17.3651V20.901C145.976 22.2912 143.79 22.9696 141.129 22.9363C135.706 22.749 132.883 20.2569 132.664 15.4577C132.799 10.3537 135.393 7.72522 140.443 7.57226C145.933 7.45363 148.628 10.4297 148.527 16.5004ZM140.622 10.7262C138.706 10.7096 137.58 11.6669 137.241 13.6003H144.155C144.121 11.7179 142.944 10.7606 140.622 10.7262Z" fill="white"/>
        <path d="M162 7.8523V10.7524H158.264C156.535 10.7191 155.688 11.5245 155.722 13.1686V22.5296H151.249V13.1176C151.214 9.57341 153.044 7.81796 156.739 7.8523H162.001H162Z" fill="white"/>
      </g>
      <defs>
        <clipPath id="clip0_61_1354">
          <rect width="162" height="28.6293" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  );
};

export default FooterLogo;
