import { styled } from "@mui/material/styles";
import { Stack, Typography, Box } from "@mui/material";
import Containter from "components/Containter";
import { ReactNode } from "react";
import { FooterLogo } from "components/Svg";
// import babylon from "assets/images/partners/babylon.png";
// import binance from "assets/images/partners/binance.png";
// import bouncebit from "assets/images/partners/bouncebit.png";
// import bybit from "assets/images/partners/bybit.png";
// import coingecko from "assets/images/partners/coingecko.png";
// import lpt from "assets/images/partners/lpt.png";
// import merlinchain from "assets/images/partners/merlinchain.png";
// import mirana from "assets/images/partners/mirana.png";
// import okx from "assets/images/partners/okx.png";
// import rockx from "assets/images/partners/rockx.png";
// import timeresearch from "assets/images/partners/timeresearch.png";

const Footer1Box = styled(Stack)`
  background: #272727;
  padding: 60px 0;
  ${({ theme }) => ({
    [theme.breakpoints.down("md")]: {
      padding: '30px 0',
    },
  })};
`;

// const Footer2Box = styled(Stack)`
//   background: #191919;
//   display: none;
//   ${({ theme }) => ({
//     [theme.breakpoints.down("md")]: {
//       display: "flex",
//     },
//   })};
// `;

const F1Box = styled(Stack)`
  ${({ theme }) => ({
    [theme.breakpoints.down("md")]: {
    },
  })};
`;

const FlexBox = styled(Stack)`
  ${({ theme }) => ({
    [theme.breakpoints.down("md")]: {
      flexDirection: 'column',
      gap: '30px',
      alignItems: 'start',
      '&.footer-box': {
        padding: '0 10px',
      }
    },
  })};
`;

const Button = styled('a')`
  width: 122px;
  height: 30px;
  line-height: 30px;
  color: rgba(255, 255, 255, 0.60);
  font-family: Arboria-Book;
  font-size: 12px;
  text-align: center;
  border-radius: 63px;
  border: 1px solid #5C5C5C;
  background: #272727;
  cursor: pointer;
  user-select: none;
  &:hover{
    color: rgba(255, 255, 255, 1);
  }
  ${({ theme }) => ({
    [theme.breakpoints.down("md")]: {
    },
  })};
`;

// const Footer1Title = styled(Typography)`
//   color: #686868;
//   font-family: Arboria-Medium;
//   font-size: 16px;
//   font-style: normal;
//   font-weight: 400;
//   line-height: normal;
// `;

const Footer2Text = styled(Typography)`
  color: rgba(255, 255, 255, 0.6);
  font-family: Arboria-Medium;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

const ImgCon = styled("img")`
  width: auto;
  height: 15px;
  margin-right: 30px;
`;

interface subConItem {
  name: string;
  path: string;
  icon?: ReactNode;
  isComing?: boolean;
  target?: string;
  desc?: string;
}

interface FooterItem {
  name: string;
  subCon: subConItem[];
}

const Footer = () => {
  // const theme = useTheme();
  // const downToMd = useMediaQuery(theme.breakpoints.down("md"));
  // const ParList = [
  //   <ImgCon src={bouncebit} />,
  //   <ImgCon src={babylon} />,
  //   <ImgCon src={coingecko} />,
  //   <ImgCon src={binance} />,
  //   <ImgCon src={okx} />,
  //   <ImgCon src={bybit} />,
  //   <ImgCon src={rockx} />,
  //   <ImgCon src={mirana} />,
  //   <ImgCon src={timeresearch} />,
  //   <ImgCon src={merlinchain} />,
  //   <ImgCon src={lpt} />,
  // ];
  return (
    <Box>
      <Footer1Box>
        <Containter>
          <Stack>
            <FlexBox
              justifyContent="space-between"
              alignItems="center"
              direction="row"
              className="footer-box"
            >
              <FlexBox justifyContent="flex-start" alignItems="center" direction="row" gap={'60px'}>
                <FooterLogo />
                {/* <Stack justifyContent="flex-start" alignItems="center" direction="row" gap={'12px'}>
                  <Button href="/pdf/pell-privacy-policy.pdf" target="_blank">Privacy Policy</Button>
                  <Button href="/pdf/pell-terms-service.pdf" target="_blank">Terms of Service</Button>
                </Stack> */}
              </FlexBox>
              <F1Box>
                <Footer2Text>© 2024 BinLayer. All rights reserved.</Footer2Text>
              </F1Box>
            </FlexBox>
            {/* <Box mt={50}>
              <Footer1Title>Supported By</Footer1Title>
            </Box>
            <Box mt={22}>
              {ParList.map((d: any) => (
                <Box style={{ display: "inline-block" }} mb={15}>
                  {d}
                </Box>
              ))}
            </Box> */}
          </Stack>
        </Containter>
      </Footer1Box>
      {/* <Footer2Box
        justifyContent="center"
        alignItems="center"
        direction="row"
        py={15}
      >
        <Footer2Text>© 2024 Pell . All rights reserved.</Footer2Text>
      </Footer2Box> */}
    </Box>
  );
};

export default Footer;
