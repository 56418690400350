import { SvgIcon } from "@mui/material";
import { IconProps } from "../type";

const LogoDark: React.FC<IconProps> = ({
  width = "162px",
  height = "29px",
}) => {
  return (
    <SvgIcon style={{ width, height }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="162"
        height="29"
        viewBox="0 0 162 29"
        fill="none"
      >
        <path
          d="M18.5948 0.000244141H0.994165C0.445103 0.000244141 0 0.445626 0 0.995032V7.63701C0 8.18642 0.445103 8.6318 0.994165 8.6318H18.5948C19.1439 8.6318 19.589 8.18642 19.589 7.63701V0.995032C19.589 0.445626 19.1439 0.000244141 18.5948 0.000244141Z"
          fill="url(#paint0_linear_61_1353)"
        />
        <path
          d="M18.5948 16.5413H0.994165C0.445103 16.5413 0 16.9866 0 17.536V24.178C0 24.7274 0.445103 25.1728 0.994165 25.1728H18.5948C19.1439 25.1728 19.589 24.7274 19.589 24.178V17.536C19.589 16.9866 19.1439 16.5413 18.5948 16.5413Z"
          fill="url(#paint1_linear_61_1353)"
        />
        <path
          d="M24.1105 5.09179L19.2864 0.264566C18.9338 -0.0881886 18.3629 -0.0881886 18.0114 0.264566L6.48596 11.8045C6.05855 12.2332 6.05855 12.9273 6.48596 13.355L18.0104 24.8866C18.3629 25.2394 18.9338 25.2394 19.2853 24.8866L24.1095 20.0594C24.462 19.7066 24.462 19.1354 24.1095 18.7837L18.5428 13.2135C18.1903 12.8607 18.1903 12.2894 18.5428 11.9377L24.1095 6.36753C24.462 6.01478 24.462 5.4435 24.1095 5.09179H24.1105Z"
          fill="#F3B112"
        />
        <path
          d="M45.8283 22.5547H35.8378V2.02734H46.0311C50.353 2.06168 52.5389 3.74013 52.5899 7.06372C52.6575 9.38732 51.5562 10.9471 49.285 11.7442C51.9451 12.507 53.3178 14.1011 53.4031 16.5267C53.5892 20.6817 51.0643 22.6911 45.8273 22.5547H45.8283ZM40.3115 4.92742V10.2947H44.9631C46.9972 10.2947 48.0132 9.379 48.0132 7.54759C48.0298 5.78381 46.9369 4.91077 44.7343 4.92742H40.3115ZM45.4206 13.6776H40.3115V19.6557H45.3957C47.7511 19.7067 48.9033 18.74 48.8534 16.7556C48.8191 14.738 47.6752 13.7119 45.4217 13.6776H45.4206Z"
          fill="black"
        />
        <path
          d="M56.0465 6.19897V1.97632H60.4953V6.19897H56.0465ZM56.0465 22.5297V7.85244H60.4953V22.5297H56.0465Z"
          fill="black"
        />
        <path
          d="M78.1376 22.5297H73.6638V13.5756C73.6472 11.7098 72.6219 10.7691 70.5878 10.7525H68.0712V22.5297H63.5974V7.85241H72.1133C76.1461 7.83576 78.1542 9.62451 78.1376 13.2197V22.5297Z"
          fill="black"
        />
        <path
          d="M96.3643 22.5548H81.2646V2.00244H85.7384V19.6558H96.3643V22.5559V22.5548Z"
          fill="black"
        />
        <path
          d="M98.6262 12.609C99.5923 9.26874 102.117 7.5726 106.202 7.52161C111.286 7.40299 113.768 9.49766 113.65 13.8046V22.5298H104.778C100.593 22.5132 98.4993 21.0127 98.4993 18.0272C98.3121 14.7546 100.736 13.1688 105.769 13.2708H109.379C109.311 11.5923 108.243 10.7266 106.176 10.6766C104.549 10.6933 103.422 11.3384 102.795 12.61H98.6262V12.609ZM109.43 16.0429H106.074C103.888 16.0262 102.812 16.6454 102.846 17.8993C102.863 19.1375 103.923 19.7567 106.024 19.7567H109.431V16.0429H109.43Z"
          fill="black"
        />
        <path
          d="M126.538 7.85254H131.342L125.343 23.1396C123.953 27.023 121.86 28.8461 119.064 28.6089C117.826 28.6255 116.768 28.355 115.886 27.7951V24.6412C116.58 25.0137 117.3 25.2093 118.047 25.226C119.131 25.2593 119.953 24.7764 120.512 23.7764L121.224 22.4539L115.198 7.85358H120.079L123.435 17.7484L126.536 7.85358L126.538 7.85254Z"
          fill="black"
        />
        <path
          d="M148.527 16.5006H137.239C137.612 18.4507 139.162 19.4174 141.891 19.4007C143.907 19.4174 145.84 18.7389 147.688 17.3653V20.9012C145.976 22.2914 143.79 22.9699 141.129 22.9366C135.706 22.7493 132.883 20.2571 132.664 15.458C132.799 10.354 135.393 7.72547 140.442 7.5725C145.933 7.45388 148.628 10.4299 148.527 16.5006ZM140.621 10.7265C138.706 10.7098 137.58 11.6672 137.241 13.6005H144.155C144.121 11.7181 142.943 10.7608 140.621 10.7265Z"
          fill="black"
        />
        <path
          d="M162 7.85254V10.7526H158.264C156.535 10.7193 155.688 11.5247 155.722 13.1688V22.5298H151.248V13.1178C151.214 9.57365 153.044 7.81821 156.739 7.85254H162.001H162Z"
          fill="black"
        />
        <defs>
          <linearGradient
            id="paint0_linear_61_1353"
            x1="1.49749"
            y1="4.27492"
            x2="19.2905"
            y2="4.36326"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#F3B112" />
            <stop offset="1" stop-color="#E8512B" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_61_1353"
            x1="1.41533"
            y1="20.8159"
            x2="19.2094"
            y2="20.9043"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#F3B112" />
            <stop offset="1" stop-color="#E8512B" />
          </linearGradient>
        </defs>
      </svg>
    </SvgIcon>
  );
};

export default LogoDark;
