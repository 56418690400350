import { SvgIcon } from "@mui/material";
import { IconProps } from "../type";

const Logo: React.FC<IconProps> = ({ width = "162px", height = "29px" }) => {
  return (
    <SvgIcon style={{ width, height }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="162"
        height="29"
        viewBox="0 0 162 29"
        fill="none"
      >
        <g clip-path="url(#clip0_30_410)">
          <path
            d="M18.5948 0H0.994165C0.445103 0 0 0.445382 0 0.994788V7.63677C0 8.18618 0.445103 8.63156 0.994165 8.63156H18.5948C19.1439 8.63156 19.589 8.18618 19.589 7.63677V0.994788C19.589 0.445382 19.1439 0 18.5948 0Z"
            fill="url(#paint0_linear_30_410)"
          />
          <path
            d="M18.5948 16.541H0.994165C0.445103 16.541 0 16.9864 0 17.5358V24.1778C0 24.7272 0.445103 25.1726 0.994165 25.1726H18.5948C19.1439 25.1726 19.589 24.7272 19.589 24.1778V17.5358C19.589 16.9864 19.1439 16.541 18.5948 16.541Z"
            fill="url(#paint1_linear_30_410)"
          />
          <path
            d="M24.1105 5.09154L19.2864 0.264322C18.9338 -0.0884328 18.3629 -0.0884328 18.0114 0.264322L6.48596 11.8043C6.05855 12.233 6.05855 12.9271 6.48596 13.3547L18.0104 24.8864C18.3629 25.2391 18.9338 25.2391 19.2853 24.8864L24.1095 20.0592C24.462 19.7064 24.462 19.1351 24.1095 18.7834L18.5428 13.2132C18.1903 12.8605 18.1903 12.2892 18.5428 11.9375L24.1095 6.36729C24.462 6.01453 24.462 5.44326 24.1095 5.09154H24.1105Z"
            fill="#F3B112"
          />
          <path
            d="M45.8283 22.5545H35.8378V2.0271H46.0311C50.353 2.06144 52.5389 3.73988 52.5899 7.06348C52.6575 9.38708 51.5562 10.9469 49.285 11.744C51.9451 12.5067 53.3178 14.1009 53.4031 16.5265C53.5892 20.6815 51.0643 22.6908 45.8273 22.5545H45.8283ZM40.3115 4.92718V10.2945H44.9631C46.9972 10.2945 48.0132 9.37875 48.0132 7.54734C48.0298 5.78357 46.9369 4.91053 44.7343 4.92718H40.3115ZM45.4206 13.6774H40.3115V19.6555H45.3957C47.7511 19.7064 48.9033 18.7398 48.8534 16.7554C48.8191 14.7377 47.6752 13.7117 45.4217 13.6774H45.4206Z"
            fill="white"
          />
          <path
            d="M56.0465 6.19872V1.97607H60.4953V6.19872H56.0465ZM56.0465 22.5295V7.85219H60.4953V22.5295H56.0465Z"
            fill="white"
          />
          <path
            d="M78.1376 22.5295H73.6638V13.5753C73.6472 11.7096 72.6219 10.7689 70.5878 10.7522H68.0712V22.5295H63.5974V7.85217H72.1133C76.1461 7.83552 78.1542 9.62426 78.1376 13.2194V22.5295Z"
            fill="white"
          />
          <path
            d="M96.3643 22.5546H81.2646V2.0022H85.7384V19.6555H96.3643V22.5556V22.5546Z"
            fill="white"
          />
          <path
            d="M98.6262 12.6087C99.5923 9.26849 102.117 7.57236 106.202 7.52137C111.286 7.40274 113.768 9.49742 113.65 13.8044V22.5296H104.778C100.593 22.5129 98.4993 21.0124 98.4993 18.027C98.3121 14.7544 100.736 13.1686 105.769 13.2705H109.379C109.311 11.5921 108.243 10.7263 106.176 10.6764C104.549 10.693 103.422 11.3382 102.795 12.6098H98.6262V12.6087ZM109.43 16.0426H106.074C103.888 16.026 102.812 16.6451 102.846 17.899C102.863 19.1373 103.923 19.7564 106.024 19.7564H109.431V16.0426H109.43Z"
            fill="white"
          />
          <path
            d="M126.538 7.85229H131.342L125.343 23.1394C123.953 27.0228 121.86 28.8459 119.064 28.6086C117.826 28.6253 116.768 28.3547 115.886 27.7949V24.6409C116.58 25.0134 117.3 25.2091 118.047 25.2257C119.131 25.259 119.953 24.7762 120.512 23.7762L121.224 22.4536L115.198 7.85334H120.079L123.435 17.7482L126.536 7.85334L126.538 7.85229Z"
            fill="white"
          />
          <path
            d="M148.527 16.5004H137.239C137.612 18.4504 139.162 19.4171 141.891 19.4005C143.907 19.4171 145.84 18.7387 147.688 17.3651V20.901C145.976 22.2912 143.79 22.9696 141.129 22.9363C135.706 22.749 132.883 20.2569 132.664 15.4577C132.799 10.3537 135.393 7.72522 140.442 7.57226C145.933 7.45363 148.628 10.4297 148.527 16.5004ZM140.621 10.7262C138.706 10.7096 137.58 11.6669 137.241 13.6003H144.155C144.121 11.7179 142.943 10.7606 140.621 10.7262Z"
            fill="white"
          />
          <path
            d="M162 7.8523V10.7524H158.264C156.535 10.7191 155.688 11.5245 155.722 13.1686V22.5296H151.248V13.1176C151.214 9.57341 153.044 7.81796 156.739 7.8523H162.001H162Z"
            fill="white"
          />
        </g>
        <defs>
          <linearGradient
            id="paint0_linear_30_410"
            x1="1.49749"
            y1="4.27468"
            x2="19.2905"
            y2="4.36301"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#F3B112" />
            <stop offset="1" stop-color="#E8512B" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_30_410"
            x1="1.41533"
            y1="20.8157"
            x2="19.2094"
            y2="20.904"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#F3B112" />
            <stop offset="1" stop-color="#E8512B" />
          </linearGradient>
          <clipPath id="clip0_30_410">
            <rect width="162" height="28.6293" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
};

export default Logo;
