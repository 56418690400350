// import styled from "@emotion/styled";
import { Stack, Typography, useTheme, useMediaQuery } from "@mui/material";
import { Logo, LogoDark } from "components/Svg";
import { NavLink } from "react-router-dom";
import { styled } from "@mui/material/styles";

const LogoWapper = styled(Stack)`
  ${({ theme }) => ({
    [theme.breakpoints.down("md")]: {
      svg: {
        height: "24px !important",
        marginTop: "10px",
      },
    },
  })};
`;

const LogoBox = ({ isTop }: { isTop: boolean }) => {
  // const theme = useTheme();
  // const downToMd = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <LogoWapper justifyContent="center" alignItems="center" direction="row">
      <NavLink style={{ lineHeight: "1" }} to={"/"}>
        {isTop ? <Logo /> : <LogoDark />}
      </NavLink>
    </LogoWapper>
  );
};

export default LogoBox;
