import { styled } from "@mui/material/styles";
import { Stack, Typography, Box } from "@mui/material";
import ImgPc from "assets/images/restaking/img-pc.png";
import ImgM from "assets/images/restaking/img-m.png";

const BanBox = styled(Stack)`
  position: relative;
  height: 1020px;
  background-color: #0E0E0E;
  overflow: hidden;
  ${({ theme }) => ({
    [theme.breakpoints.down("md")]: {
      height: '641px',
    },
  })};
`;

const ImgBox = styled('div')`
  width: 1826px;
  height: 1054px;
  background: url(${ImgPc}) no-repeat center;
  background-size: contain;
  position: absolute;
  right: -420px;
  bottom: -210px;
  z-index: -1;
  ${({ theme }) => ({
    [theme.breakpoints.down("md")]: {
      width: '450px',
      height: '405px',
      background: `url(${ImgM}) no-repeat center`,
      backgroundSize: 'contain',
      left: '50%',
      bottom: '0px',
      transform: 'translate(-45%, 0)',
    },
  })};
  @media screen and (min-width: 900px) and (max-width: 1199px) {
    zoom: 0.68;
    right: -300px;
  }
`;

const Containter = styled(Stack)`
  height: 100%;
  width: 1200px;
  position: relative;
  z-index: 3;
  padding: 0 25px;
  ${({ theme }) => ({
    [theme.breakpoints.down("md")]: {},
  })};
  @media screen and (min-width: 2000px) {
    /* width: 80%; */
  }
`;

const Head = styled(Typography)`
  color: #FFF;
  font-family: Arboria-Medium;
  font-size: 64px;
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  z-index: 5;
  margin-bottom: 52px;
  ${({ theme }) => ({
    [theme.breakpoints.down("md")]: {
      fontSize: "36px",
      fontFamily: 'Arboria-Bold',
      marginBottom: '30px',
    },
  })};
`;

const Desc = styled(Typography)`
  color: #FFF;
  font-family: Arboria-Book;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  z-index: 5;
  width: 100%;
  max-width: 370px;
  ${({ theme }) => ({
    [theme.breakpoints.down("md")]: {
      fontSize: "14px",
      lineHeight: '20px',
      maxWidth: 'auto',
    },
  })};
`;

const HeadBox = styled('div')`
  margin-top: 86px;
  ${({ theme }) => ({
    [theme.breakpoints.down("md")]: {
      marginTop: '40px',
    },
  })};
`;

const FlagBox = styled('div')`
  display: flex;
  align-items: center;
  gap: 5px;
  margin-bottom: 10px;
`;

const Flag = styled('div')<{bg?: string}>`
  width: 10px;
  height: 10px;
  background-color: ${({bg}) => bg ? bg : '#fff'};
  border-radius: 50%;
`;

const Restaking = () => {
  return (
    <BanBox justifyContent="center" alignItems="center" direction="row">
      <Containter>
        <HeadBox>
          <FlagBox>
            <Flag bg="#EA6227" />
            <Flag />
          </FlagBox>

          <Head>Restaking Applications</Head>
          <Desc>BinLayer is a staking platform for BNB and its LSD.</Desc>
          <Desc>Through authorising BinLayer’s smart contracts to impose additional slashing conditions on BNB and it‘s LSD, stakers contribute to enhanced cryptonomic security of the BNB ecosystem. </Desc>
        </HeadBox>
        <ImgBox />
      </Containter>
    </BanBox>
  );
};

export default Restaking;
