import { styled } from "@mui/material/styles";
import { Stack, Typography, Box } from "@mui/material";
import WorkPc from "assets/images/work/work-pc.png";
import WorkM from "assets/images/work/work-m.png";

const BanBox = styled(Stack)`
  position: relative;
  height: 950px;
  background-color: #F19B19;
  ${({ theme }) => ({
    [theme.breakpoints.down("md")]: {
      height: '556px',
    },
  })};
`;

const ImgBox = styled('div')`
  width: 100%;
  height: 410px;
  background: url(${WorkPc}) no-repeat center;
  background-size: contain;
  margin-top: 138px;
  ${({ theme }) => ({
    [theme.breakpoints.down("md")]: {
      height: '286px',
      background: `url(${WorkM}) no-repeat center`,
      backgroundSize: 'contain',
      marginTop: '60px',
    },
  })};
`;

const Containter = styled(Stack)`
  height: 100%;
  width: 1200px;
  position: relative;
  z-index: 3;
  padding: 0 25px;
  ${({ theme }) => ({
    [theme.breakpoints.down("md")]: {},
  })};
  @media screen and (min-width: 2000px) {
    /* width: 80%; */
  }
`;

const Head = styled(Typography)`
  color: #FFF;
  font-family: Arboria-Medium;
  font-size: 64px;
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  z-index: 5;
  ${({ theme }) => ({
    [theme.breakpoints.down("md")]: {
      fontSize: "36px",
      fontFamily: 'Arboria-Bold',
    },
  })};
`;

const HeadBox = styled('div')`
  margin-top: 111px;
  ${({ theme }) => ({
    [theme.breakpoints.down("md")]: {
      marginTop: '40px',
    },
  })};
`;

const FlagBox = styled('div')`
  display: flex;
  align-items: center;
  gap: 5px;
  margin-bottom: 10px;
`;

const Flag = styled('div')<{bg?: string}>`
  width: 10px;
  height: 10px;
  background-color: ${({bg}) => bg ? bg : '#fff'};
  border-radius: 50%;
`;

const HowToWork = () => {
  return (
    <BanBox justifyContent="center" alignItems="center" direction="row">
      <Containter>
        <HeadBox>
          <FlagBox>
            <Flag bg="#EA6227" />
            <Flag />
          </FlagBox>
          <Head>How does BinLayer work?</Head>
        </HeadBox>
        <ImgBox />
      </Containter>
    </BanBox>
  );
};

export default HowToWork;
