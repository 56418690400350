import { styled } from "@mui/material/styles";
import { Stack, Typography, useMediaQuery, useTheme, Box } from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import { ReactNode, useState } from "react";
import "swiper/css";
import "swiper/css/pagination";
import Grid from "@mui/material/Unstable_Grid2";

import Work1 from "assets/images/work-1.png";
import Work2 from "assets/images/work-2.png";
import Work3 from "assets/images/work-3.png";
import Work3M from "assets/images/work-3-m.png";
import { About3 } from "components/Svg";

const BanBox = styled(Stack)`
  width: 100%;
  background: #fff;
  position: relative;
  overflow: hidden;
  ${({ theme }) => ({
    [theme.breakpoints.down("md")]: {},
  })};
`;

const Dot = styled(Box)<{ color: string }>`
  width: 10px;
  height: 10px;
  background: ${({ color }) => (color ? color : "#F3B112")};
  border-radius: 50%;
`;

const Head = styled(Typography)`
  color: #000;
  font-family: Arboria-Medium;
  font-size: 64px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  ${({ theme }) => ({
    [theme.breakpoints.down("md")]: {
      fontSize: "36px",
    },
  })};
`;

const Containter = styled(Stack)`
  width: 1200px;
  position: relative;
  padding: 90px 25px 180px;
  box-sizing: border-box;
  ${({ theme }) => ({
    [theme.breakpoints.down("md")]: {
      width: "100%",
      padding: "60px 20px 60px",
    },
  })};
  @media screen and (min-width: 2000px) {
    /* width: 80%; */
  }
`;

const MSwiperBox = styled("div")`
  width: 100%;
  box-sizing: border-box;
`;

const ImgCon = styled("img")`
  width: 100%;
  height: auto;
`;

const Title = styled(Typography)`
  color: #000;
  font-family: Arboria-Medium;
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

const Desc = styled(Typography)`
  color: #000;
  font-family: Arboria-Book;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
`;

const Work3Box = styled(Stack)`
  ${({ theme }) => ({
    [theme.breakpoints.down("md")]: {
      marginTop: "40px",
    },
  })};
`;

const SwiperBox = styled(Stack)`
  .swiper-pagination-bullet {
    width: 34px;
    height: 4px;
    background: #565656;
    margin: 0 0 !important;
    &:first-child {
      border-radius: 28px 0 0 28px;
    }
    &:last-child {
      border-radius: 0 28px 28px 0;
    }
  }

  .swiper-pagination-bullet-active {
    color: #fff;
    border-radius: 28px;
    background: #f3b112;
  }
`;

const Needed = () => {
  const [swiperRef, setSwiperRef] = useState<any>(null);
  const theme = useTheme();
  const downToMd = useMediaQuery(theme.breakpoints.down("md"));
  const WorkCon1 = () => (
    <Box>
      <Title>Staking Pools</Title>
      <Desc mt={30}>
        Aggregating BNB liquidity to capture profits from validation activities
        and MEV on the underlying chain.
      </Desc>
      <Desc mt={15}>How it works</Desc>
      <Stack
        mt={15}
        justifyContent="flex-start"
        alignItems="flex-start"
        direction="row"
      >
        <Desc>1. </Desc>
        <Desc>
          Stake: Stake any amount of your BNB tokens to access daily staking
          rewards
        </Desc>
      </Stack>
      <Stack
        justifyContent="flex-start"
        alignItems="flex-start"
        direction="row"
      >
        <Desc>2. </Desc>
        <Desc>
          Receive stToken: Receive liquid stTokens and start receiving rewards
          immediately
        </Desc>
      </Stack>
      <Stack
        justifyContent="flex-start"
        alignItems="flex-start"
        direction="row"
      >
        <Desc>3. </Desc>
        <Desc>
          Use in DeFi: Use your stTokens across DeFi to get more reward
          opportunities to your daily staked rewards.
        </Desc>
      </Stack>
    </Box>
  );
  const WorkCon2 = () => (
    <Box>
      <Title>Restaking Pools</Title>
      <Desc mt={30}>
        Restaking aggregates LSD and redistributes liquidity, thereby improves
        BNB’s economic utility and captures value in a broader scenario.
      </Desc>
    </Box>
  );
  return (
    <BanBox justifyContent="center" alignItems="center" direction="row">
      <Containter>
        <Stack
          justifyContent="flex-start"
          alignItems="center"
          direction="row"
          mt={43}
        >
          <Dot color={"#F3B112"} />
          <Dot ml={5} color={"#D9D9D9"} />
        </Stack>

        {!downToMd ? (
          <>
            <Head>How does BinLayer work?</Head>
            <Box mt={80}>
              <Grid container spacing={30}>
                <Grid xs={6} md={6}>
                  <WorkCon1></WorkCon1>
                </Grid>
                <Grid xs={6} md={6}>
                  <Box pl={150}>
                    <ImgCon src={Work1} alt="" />
                  </Box>
                </Grid>
              </Grid>
              <Grid mt={60} container spacing={30}>
                <Grid xs={6} md={6}>
                  <Box pr={150}>
                    <ImgCon src={Work2} alt="" />
                  </Box>
                </Grid>
                <Grid xs={6} md={6}>
                  <Stack
                    justifyContent="flex-start"
                    alignItems="center"
                    direction="row"
                    height={"100%"}
                  >
                    <WorkCon2></WorkCon2>
                  </Stack>
                </Grid>
              </Grid>
            </Box>
          </>
        ) : (
          <>
            <Head mt={10}>
              How does <br /> BinLayer work?
            </Head>
            <SwiperBox mt={60}>
              <Swiper
                onSwiper={setSwiperRef}
                initialSlide={0}
                spaceBetween={20}
                pagination={true}
                modules={[Pagination]}
                style={{ width: "100%" }}
              >
                <SwiperSlide style={{ height: "800px" }}>
                  <MSwiperBox>
                    <Box mb={30} px={20}>
                      <ImgCon src={Work1} alt="" />
                    </Box>
                    <WorkCon1></WorkCon1>
                  </MSwiperBox>
                </SwiperSlide>
                <SwiperSlide style={{ height: "800px" }}>
                  <MSwiperBox>
                    <Box mb={30} px={20}>
                      <ImgCon src={Work2} alt="" />
                    </Box>
                    <WorkCon2></WorkCon2>
                  </MSwiperBox>
                </SwiperSlide>
              </Swiper>
            </SwiperBox>
          </>
        )}
        {!downToMd && (
          <Stack
            width={"100%"}
            height={1}
            my={120}
            style={{ borderBottom: "1px solid #EEECEA" }}
          ></Stack>
        )}
        <Work3Box>
          <ImgCon src={downToMd ? Work3M : Work3} />
        </Work3Box>
      </Containter>
    </BanBox>
  );
};

export default Needed;
