import { styled } from "@mui/material/styles";
import {
  Stack,
  Typography,
  Box,
  useTheme,
  useMediaQuery,
  Button,
} from "@mui/material";
import BannerBg from "assets/images/banner.png";
import BannerBgM from "assets/images/banner-m.png";

const BanBox = styled(Stack)`
  width: 100%;
  height: 100vh;
  background: #000;
  position: relative;
  overflow: hidden;
  ${({ theme }) => ({
    [theme.breakpoints.down("md")]: {
      height: "850px",
    },
  })};

  @media screen and (min-width: 900px) and (max-width: 1199px) {
    align-items: flex-start;
  }
`;

const BannerBox = styled("div")`
  height: 732px;
  width: 1200px;
  background: url(${BannerBg}) no-repeat;
  background-position: right center;
  background-size: cover;
  position: absolute;
  z-index: -1;
  right: 0;
  top: 0;
  ${({ theme }) => ({
    [theme.breakpoints.down("md")]: {
      height: "100%",
      background: `url(${BannerBgM}) no-repeat`,
      backgroundPosition: "bottom 150px center",
      backgroundSize: "90% auto",
      width: "100%",
    },
  })};
  @media screen and (min-width: 2000px) {
    width: 80%;
    height: 100%;
  }
  @media screen and (min-width: 475px) and (max-width: 900px) {
    background-position: center;
  }
  @media screen and (min-width: 900px) and (max-width: 1199px) {
    height: 100vh;
    background: url(${BannerBgM}) no-repeat;
    background-position: 80% center;
  }
`;

const Containter = styled(Stack)`
  height: 732px;
  width: 1200px;
  padding: 0 120px;
  position: relative;
  z-index: 2;
  ${({ theme }) => ({
    [theme.breakpoints.down("md")]: {
      height: "100%",
      width: "100%",
      padding: "0 5px",
    },
  })};
  @media screen and (min-width: 2000px) {
    width: 70%;
  }
`;

const PcBox = styled(Stack)`
  ${({ theme }) => ({
    [theme.breakpoints.down("md")]: {
      padding: "100px 20px 60px 20px",
    },
  })};
`;

const TextBox = styled(Box)`
  width: 560px;
  ${({ theme }) => ({
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  })};
  @media screen and (min-width: 2000px) {
    width: 30vw;
  }
`;

const BannerButton = styled(Button)`
  &.Mui-disabled {
    color: #5d5d5d;
    background-color: #3f3f3f;
  }
  ${({ theme }) => ({
    [theme.breakpoints.down("md")]: {
      width: "100%",
      height: "45px",
      fontSize: "14px",
    },
  })};
`;

const HeadPc = styled(Typography)`
  margin: 0;
  font-size: 80px;
  font-family: Arboria-Bold;
  font-weight: 300;
  line-height: 80px;
  background-image: linear-gradient(
    to right,
    #fff,
    #fff,
    #fff,
    rgba(243, 177, 18, 0.8),
    rgba(235, 102, 38, 0.8)
  );
  background-clip: text;
  color: transparent;
  ${({ theme }) => ({
    [theme.breakpoints.down("md")]: {
      fontSize: "50px",
      lineHeight: "50px",
    },
  })};
  @media screen and (min-width: 2000px) {
    font-size: 3.5vw;
    line-height: 1;
  }
`;

const DescPc = styled(Typography)`
  color: #fff;
  font-family: Arboria-Book;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.55;
  max-width: 420px;
  ${({ theme }) => ({
    [theme.breakpoints.down("md")]: {
      fontSize: "14px",
    },
  })};
  @media screen and (min-width: 2000px) {
    font-size: 1vw;
  }
`;

const Banner = () => {
  const theme = useTheme();
  const upToLg = useMediaQuery(theme.breakpoints.up("lg"));
  return (
    <BanBox justifyContent="center" alignItems="center" direction="row">
      <Containter>
        <PcBox
          justifyContent={{ xs: "space-between", md: "center" }}
          alignItems={"statr"}
          direction={"column"}
          height={"100%"}
        >
          <TextBox>
            <Box>
              <HeadPc>Unleash BNB Chain’s True Potential</HeadPc>
            </Box>
            <Box>
              <DescPc mt={30}>
                BinLayer aims to emancipate capital efficiency and improve
                cryptonomic security of the BNB ecosystem
              </DescPc>
            </Box>
          </TextBox>
          <Box mt={50}>
            <a href="https://app.binlayer.xyz/" target="_blank">
              <BannerButton variant="contained">Launch App</BannerButton>
            </a>
          </Box>
        </PcBox>
        <BannerBox></BannerBox>
      </Containter>
    </BanBox>
  );
};

export default Banner;
